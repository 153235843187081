<script>
export default {
  name: 'BgSpiralCoral',

  props: {
    w: {
      type: [Number, String],
      default: null,
    },
    h: {
      type: [Number, String],
      default: null,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>

<template>
  <img
    src="/imgs/backgrounds/spiral-coral.png"
    :style="{ 'width': w, 'height': h, 'max-width': w, 'max-height': h }"
    alt="Spiral Coral"
  >
</template>
