<script>
export default {
  name: 'BgSpiralCoralWeb',

  props: {
    w: {
      type: [Number, String],
      default: null,
    },
    h: {
      type: [Number, String],
      default: null,
    },
    white: {
      type: String,
      default: 'currentwhite',
    },
  },
}
</script>

<template>
  <img
    src="/imgs/backgrounds/spiral-coral-web.png"
    :style="{ 'width': w, 'height': h, 'max-width': w, 'max-height': h }"
    alt="Spiral Coral Web"
  >
</template>
